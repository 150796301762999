import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"
import {CookiesPoup} from "../homepage"
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/images/market_ui/slack/blog_image.png");
const image1 = require("../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../assets/images/market_ui/slack/img_4.png");

const BlogHeader = require("../../assets/img/blogs/ai_header_blog_images_5.png.webp");
const Section1 = require("../../assets/img/blogs/chatbot_best_1.png");
const Section2 = require("../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Artificial Intelligence (AI): AI is here, what&#039;s next?  "
        description="With AI in the right context and the right code, there are lots of opportunities to unravel and make an impact with automation."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Artificial Intelligence (AI): AI is here, what’s
                            next?
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <h6 className="font-section-sub-header-small-bold">
                          Better analysis on how to shape the business outcomes.
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Did you know the UK government plans to invest 17.3
                          million pounds for a university research on Artificial
                          Intelligence (AI)? This action may boomerang 654
                          Billion pounds back into the country’s economy. That’s
                          the potential grandeur of applying AI in different
                          fields and industries of the world today! The UK
                          government seems to have made the right move and at an
                          opportune time too.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          AI is the intelligence exhibited by machines. These
                          machines have competence in reasoning, learning,
                          language processing, planning, and perception to help
                          improve decision making of businesses. Basically, AI’s
                          data crunching power will outsmart the smartest
                          technologies of today.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Google Maps uses cognitive technology to predict
                          traffic information. UK’s NHS is adopting an AI
                          powered chatbot for non-emergency cases. Expedia, a
                          flight and hotel search and booking site states that
                          their core services were built on machine learning.
                          Right from Apple’s Siri, Amazon’s Alexa etc.,
                          artificial intelligence interacts with smart
                          technology at the right context. More recently,
                          Salesforce in collaboration with IBM, launched an AI
                          platform, Einstein which offers data-based insights
                          for businesses. For instance, to offer customer’s
                          online buying behavior statistics to generate targeted
                          campaigns.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          Statistics to boggle your mind
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A Gartner report says 85% customer interactions will
                          be managed without a human by 2020. As of now the most
                          advanced machine cannot compare to the human brain.
                          Harvard scientists were awarded 28 million dollars to
                          study the human brain, which will help develop AI to
                          be as strong as the human brain! There is an
                          increasing trend of companies employing AI to replace
                          human workforce. An Infosys report states
                          organisations already employed or plan to employ AI
                          can expect 39% increase in their revenue and 37%
                          reduction in their cost by 2020. Japanese Insurance
                          firm, Fukoku Mutual Life Insurance is replacing its
                          workforce of 34 employees with IBM’s AI Watson
                          Explorer. The AI will calculate the insurance policy
                          payouts and help increase the firm’s productivity by
                          30%. If this has not got you thinking about AI then
                          consider this. In a decade, 16% of American jobs will
                          be replaced by Artificial Intelligence. Now, the
                          question is, are you on board with AI? If you need
                          more evidence, here are AI developments just to warm
                          up.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          AI Development & Progress
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Artificial Intelligence has come a long way since the
                          Watson Jeopardy win in 2011. Watson is an intelligent
                          system developed by IBM, and is capable of answering
                          questions in the natural language.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          From machine learning to deep learning
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Artificial Intelligence has moved from the era of
                          machine learning to deep learning. So, from a scenario
                          where machines were being taught to assist man,
                          machines are now able to learn, process, and provide
                          solutions unassisted. Computers have gone far beyond
                          the stage where they have to be taught, they can now
                          assess information unaided. Technology now enables you
                          to interact with AI without having to be fastened to a
                          laptop or device.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-process-automation">
                            AI has the ability of speech recognition
                          </a>{" "}
                          and is swiftly headed towards natural language
                          interaction and generation. With this ability and
                          capability to read and write, corporations investing
                          in AI will surely reap major benefits. Image
                          recognition, is no longer only a human feat, take for
                          example Facebook’s image recognition to tag pictures.
                          The social media platform’s capacity has exploded over
                          the last few years with billions of photos being
                          uploaded every year. AI now enables their datasets to
                          not only perform facial recognition but also identify
                          the picture.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          AI: The Intelligent Attorney
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Built with Watson, ROSS is the world’s first
                          artificial intelligent attorney. ROSS runs on the same
                          technology that enabled Watson to win Jeopardy.
                          Lawyers ask ROSS questions in natural language and the
                          AI responds with very relevant, evidence based
                          answers. With this feat, IBM has pushed the spirit of
                          cognitive computing into the legal sphere. ROSS is an
                          intelligence system and is able to recognize and
                          analyze data to provide solutions for lawyers. The
                          machine is designed to understand legal language,
                          formulate hypotheses, answer questions and monitor
                          developments in the legal system. ROSS can monitor
                          legal proceedings round the clock and notify the
                          lawyer of new court decisions that may affect their
                          case. So, this is just the tip of the iceberg, AI is
                          slowly but steadily pervading into every walk of life.
                          Are you a part of the game or are you still a sceptic?
                          Here’s more evidence.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          AI reforms Healthcare
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Built with Watson, ROSS is the world’s first
                          artificial intelligent attorney. ROSS runs on the same
                          technology that enabled Watson to win Jeopardy.
                          Lawyers ask ROSS questions in natural language and the
                          AI responds with very relevant, evidence based
                          answers. With this feat, IBM has pushed the spirit of
                          cognitive computing into the legal sphere. ROSS is an
                          intelligence system and is able to recognize and
                          analyze data to provide solutions for lawyers. The
                          machine is designed to understand legal language,
                          formulate hypotheses, answer questions and monitor
                          developments in the legal system. ROSS can monitor
                          legal proceedings round the clock and notify the
                          lawyer of new court decisions that may affect their
                          case. So, this is just the tip of the iceberg, AI is
                          slowly but steadily pervading into every walk of life.
                          Are you a part of the game or are you still a sceptic?
                          Here’s more evidence.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Step Into The Future
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Have you heard of the Turing Test? This evaluates
                          whether or not if the computer is intelligent.
                          Originally designed in the 1950’s, this test was
                          passed by a computer passed only recently. So,
                          according to the Guardian, it may be time to pledge
                          fealty to the machines. Why? Because computers will be
                          able to pass the Turing Test for all practical
                          purposes. This shows that AI is steadily becoming the
                          dominant technology as enterprises look to maintain
                          the competitive edge.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A whooping 82 percent of research participants stated
                          their businesses will adopt artificial intelligence in
                          2017. These enterprises are resorting to AI for:{" "}
                        </p>
                        <ul className="font-section-small-text" style={{ width: "100%", float: "left" }}>
                          <li className="font-section-small-text">
                            Automation of large amounts of structured and
                            unstructured data.
                          </li>
                          <li className="font-section-small-text">
                            Better analysis on how to shape the business
                            outcomes.
                          </li>
                          <li className="font-section-small-text">
                            Convert numbers into patterns and predictions.
                          </li>
                          <li className="font-section-small-text">
                            {" "}
                            <a href="https://workativ.com/conversational-ai-platform/chatbot-webinar">
                              Increase overall productivity of your business.
                            </a>
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          And they say that AI has barely scratched the surface
                          and is yet to reach its full potential. With AI in the
                          right context and the right code, there are lots of
                          opportunities to unravel and <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">make an impact with
                          automation.</a> So, are you in?
                        </p>
                      </div>

                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul" style={{ width: "100%", float: "left" }}>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                              Conversational AI Chatbot for employee service automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              What Is Conversational AI? A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://workativ.com/conversational-ai-platform">
                        <img src={topImage} alt="slack chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://workativ.com/conversational-ai-platform"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};

const HelpdeskWrapper = (props) => {
  return (
    <div className="nocode_wrapper helpdesk_wrapper">
      <h4>
        <span>
          <img src={require("../../assets/images/arrow_blog.svg")} alt="img" />
        </span>
        {props.header}
      </h4>
    </div>
  );
};
